<template lang="pug">
  div.wrap-module-others
    div(v-for="item in list"
      @click="onList(item)").others-list.f.fc
      div.list-content.f.fm.flex-between.py8
        span.fz14 {{item.label}}
        v-icon(v-if="item.href || item.method") mdi-chevron-right
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-others {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .others-list {
    position: relative;
    border-bottom: solid $border_size $border_color;
    .list-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      margin: 0 auto;
    }
  }
}
</style>

<script>
import { BUILT_TIME } from '@/built-info'
import { format } from 'date-fns'

export default {
  data() {
    let list = [
      {
        label: 'Meet Awesome 人生を豊かにする出会いをもっと',
        href: 'https://www.meetawesome.jp'
      },
      {
        label: 'プライバシーポリシー',
        href: 'https://www.meetawesome.jp/privacypolicy'
      },
      {
        label: '利用規約',
        href: 'https://www.meetawesome.jp/termsofservice'
      },
      {
        label: 'サービスレベル契約',
        href: 'https://www.meetawesome.jp/sla'
      },
      {
        label: '特定商取引法に基づく表記',
        href: 'https://www.meetawesome.jp/nscta'
      }
    ]
    if (BUILT_TIME > 0) {
      list.push({
        label: `ビルド：${format(new Date(BUILT_TIME * 1000), 'yyyy-MM-dd HH:mm:ss')}`
      })
    }

    return {
      list
    }
  },
  methods: {
    onList(item) {
      if (item.href) window.open(item.href)
      if (item.method) item.method()
    }
  }
}
</script>
