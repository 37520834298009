<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    Header(:content="headerContent")
    DrawerMenu(v-if="uid" :uid="uid" :show="showMenu" @close="toggleMenu")
    div.wrap-meeting.f.fh
      ModuleAbout
</template>

<style lang="scss" scoped>
.wrap-meeting {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import DrawerMenu from '@/components/shared/DrawerMenu'
import ModuleAbout from '@/components/module/ModuleAbout'

export default {
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModuleAbout
  },
  data() {
    return {
      headerContent: {
        title: 'Meet Awesome について',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        }
      },
      showMenu: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
